import React from "react";
import ReportManager from "commons/components/ReportManager";

const filters = [
  {
    type: "filter",
    name: "service-locations",
    key: "service_location_id",
    extras: { label: "cars" },
    filters: { type: "CAR" },
  },
];

const columns = [
  {
    name: "reference",
    type: "link",
    field: "reference",
    base: "sales",
  },
  {
    name: "customer",
    type: "text",
  },
  {
    name: "code",
    type: "text",
  },
  { name: "total", type: "money" },
  { name: "guest", type: "text" },
  { name: "passengers", type: "number" },
  { name: "service", type: "text" },
  { name: "date", type: "date" },
  { name: "time", type: "time" },
  { name: "pickup", type: "text" },
  { name: "dropoff", type: "text" },
  { name: "language", type: "text" },
  { name: "guide", type: "text" },
  { name: "car", type: "text" },
  { name: "external_car", type: "text" },
  { name: "notes", type: "text" },
  { name: "representative", type: "text" },
];

export default function CarMovementReport() {
  return (
    <ReportManager
      url="car-rentals"
      title="car-movement"
      columns={columns}
      filterByDate
      dateField="sales.date"
      filters={filters}
      inlineFilters={[
        "customer",
        "code",
        "total",
        "guest",
        "passengers",
        "service",
        "reference",
        "date",
        "time",
        "pickup",
        "dropoff",
        "language",
        "guide",
        "representative",
        "car",
        "external_car",
        "notes",
      ]}
      totals={["total", "passengers"]}

      // initialQuery={{
      //   "sale_lines.type": "CAR_RENTAL",
      // }}
    />
  );
}
