import React, { useState } from "react";
import ResourceListPage from "commons/components/ResourceListPage";
import CardSection from "commons/components/CardSection";
import useResourceMachine from "commons/hooks/useResourceMachine";
import Editor from "../forms/Editor";
import ResourceEditor from "commons/components/ResourceEditor";
import {
  IconButton,
  Dialog,
  Box,
  Zoom,
  Fab,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";

const columns = [
  {
    name: "id",
    type: "number",
    sortable: true,
  },
  {
    name: "template",
    type: "text",
    sortable: true,
  },
  {
    name: "user",
    type: "text",
    sortable: true,
  },
  {
    name: "created",
    type: "datetime",
    sortable: true,
  },
];

export default function Forms({
  id,
  field = "customer_id",
  baseQuery = {},
  Embeded = EmbededForm,
}) {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <ResourceListPage
        Wrapper={CardSection}
        hideToolbar
        url="forms"
        columns={columns}
        baseQuery={{ [field]: id, ...baseQuery }}
        createButton={
          <Box position="fixed" bottom={24} right={24}>
            <Zoom in style={{ transitionDelay: "500ms" }}>
              <Fab color="primary" onClick={onOpen}>
                <Add />
              </Fab>
            </Zoom>
          </Box>
        }
      />
      <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
        <DialogTitle disableTypography>
          <Box p={1}>
            <IconButton
              aria-label="close"
              style={{ position: "absolute", top: "4px", right: "4px" }}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box p={2}>
            <Embeded id={parseInt(id)} />
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            <Save />
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

function EmbededForm({ id }) {
  const { current, send, updateModel } = useResourceMachine("forms", "create", {
    customer_id: id,
  });

  return (
    <ResourceEditor current={current} send={send} embeded>
      <Editor
        current={current}
        send={send}
        updateModel={updateModel}
        disableCustomer
      />
    </ResourceEditor>
  );
}
