import React, { useMemo } from "react";
import ReportManager, {
  groupByCurrency,
} from "commons/components/ReportManager";
import useQueryAllResources from "commons/hooks/useQueryAllResources";
import { IconButton, Tooltip } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import useTranslate from "commons/hooks/useTranslate";
import { useHistory } from "react-router-dom";
import { groupBy, prop } from "ramda";

const filters = [
  {
    type: "filter",
    name: "files",
    key: "file_id",
    extras: {
      optionLabel: "name",
    },
  },
  {
    type: "filter",
    name: "employees",
    key: "operator_id",
    extras: {
      optionLabel: "name",
      label: "operators",
    },
  },
  {
    type: "select",
    name: "file_type",
    key: "file_type",
    options: [
      {
        id: "normal",
        name: "normal",
      },
      {
        id: "optional",
        name: "optional",
      },
    ],
  },
];

const columns = [
  {
    name: "operator",
    type: "text",
  },
  {
    name: "guests_count",
    type: "number",
  },
  {
    name: "sales_count",
    type: "number",
  },
  {
    name: "sales_total",
    type: "money",
    highlight: true,
  },
  {
    name: "purchases_count",
    type: "number",
  },
  {
    name: "purchases_total",
    type: "money",
    highlight: true,
  },
  {
    name: "profit",
    type: "money",
    highlight: true,
  },
  {
    name: "profit_percentage",
    type: "percentage",
    nominator: "profit",
    denominator: "sales_total",
    highlight: true,
  },
  {
    name: "operator_files",
    type: "number",
    highlight: true,
  },
  {
    name: "operator_files_percentage",
    type: "percentage_number",
    highlight: true,
  },
];

const groupByOperator = (records) => groupBy(prop("operator"), records);

export default function FilesByOperatorReport() {
  const { t } = useTranslate();
  const history = useHistory();
  const [currencies] = useQueryAllResources("currencies");

  const lookup = useMemo(
    () =>
      currencies.reduce((a, c) => {
        return {
          ...a,
          [c.id]: c.name,
        };
      }, {}),
    [currencies]
  );

  const extractCurrencyName = (records) => {
    return records.flatMap((c) => {
      return Array.isArray(c) && c.length > 0
        ? lookup[c[0].currency_id]
        : undefined;
    });
  };

  return (
    <ReportManager
      url="tourism-balance"
      title="filesByOperatorReport"
      columns={columns}
      filters={filters}
      recordsFunc={(recs) => {
        const totalFiles = recs.length;
        const groupedByCurrency = groupByCurrency(recs);
        const groupedByOperator = groupedByCurrency.map(groupByOperator);
        const resultTotals = groupedByOperator.map((g) => {
          return Object.values(g).map((group) => {
            const operator_files = group.length;
            const operator_files_percentage = operator_files / totalFiles;

            const totals = group.reduce(
              (sum, record) => ({
                ...sum,
                currency_id: record.currency_id,
                operator: record.operator,
                guests_count: sum.guests_count + Number(record.guests_count),
                purchases_count:
                  sum.purchases_count + Number(record.purchases_count),
                purchases_total:
                  sum.purchases_total + Number(record.purchases_total),
                sales_count: sum.sales_count + Number(record.sales_count),
                sales_total: sum.sales_total + Number(record.sales_total),
                profit: sum.profit + Number(record.profit),
                //   profit_percentage: sum.profit_percentage + Number(record.profit_percentage),
              }),
              {
                guests_count: 0,
                purchases_count: 0,
                purchases_total: 0,
                sales_count: 0,
                sales_total: 0,
                profit: 0,
                profit_percentage: 0,
              }
            );

            return {
              ...totals,
              operator_files,
              operator_files_percentage,
              profit_percentage:
                totals.sales_total !== 0
                  ? totals.profit / totals.sales_total
                  : 0,
            };
          });
        });
        return resultTotals;
      }}
      titlesFunc={extractCurrencyName}
      totals={[
        "guests_count",
        "sales_count",
        "sales_total",
        "purchases_count",
        "purchases_total",
        "profit",
        "profit_percentage",
        "operator_files",
      ]}
      filterByDate
      initialQuery={{ type: "files" }}
      toolbarExtras={
        <Tooltip title={t("inactiveFilesReport")}>
          <IconButton
            onClick={() => history.push(`/s/reports/inactive-files/`)}
          >
            <DeleteOutline />
          </IconButton>
        </Tooltip>
      }
    />
  );
}
