import React from "react";
import ReportManager from "commons/components/ReportManager";

const columns = [
  {
    name: "ship",
    type: "link",
    field: "id",
    base: "cruises",
  },
  {
    name: "starts",
    type: "date",
  },
  {
    name: "ends",
    type: "date",
  },
  {
    name: "captain",
    type: "text",
  },
];

export default function InactiveCruisesReport() {
  return (
    <ReportManager
      url="inactive"
      title="inactiveCruisesReport"
      columns={columns}
      initialQuery={{ type: "cruises" }}
      filterByDate
      // initialQuery={{ "sales.cruise_id": null, "purchases.cruise_id": null }}
    />
  );
}
