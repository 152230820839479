import React, { useCallback, useEffect, useMemo, useState } from "react";
import useTranslate from "commons/hooks/useTranslate";
import PageCard from "./PageCard";
import {
  Toolbar,
  Typography,
  IconButton,
  Button,
  ButtonGroup,
  Box,
  Grid,
  Portal,
  Zoom,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  TablePagination,
  Tooltip,
  TextField,
} from "@material-ui/core";
import {
  FilterList,
  ViewList,
  ArrowRight,
  ArrowLeft,
  Check,
  // PieChart,
  CancelOutlined,
  CheckCircleOutline,
  DoneAll,
  // Bookmark,
} from "@material-ui/icons";
import { Link, Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import CardSection from "./CardSection";
import { FormDateTimeField } from "commons/components/FormDateField";
import FormSelectField from "./FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import Stack from "./Stack";
import ErrorAlert from "./ErrorAlert";
import LoadingIndicator from "./LoadingIndicator";
import { ExcelExporterButton } from "commons/components/ResourceListPage";
import { prop, groupBy, uniq } from "ramda";
import { sumField } from "commons/helpers/utils";
// import { VictoryChart, VictoryAxis, VictoryTheme, VictoryBar } from "victory";
// import { Chart } from "react-charts";
import { useMutation } from "react-query";
import api from "commons/helpers/api";
import { usePrintManager, PrintButton, PrintTemplate } from "./PrintManager";
import "react-vis/dist/style.css";
import {
  XYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
} from "react-vis";
import FormSwitch from "./FormSwitch";
import FormTextField from "./FormTextField";
import { DashboardShortcutPin } from "./DashboardShortcut";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export function useReportQuery(initialQuery = {}) {
  const [query, setQuery] = useState({});
  const [duration, setDuration] = useState({
    start: dayjs().startOf("DAY"),
    end: dayjs().endOf("DAY"),
  });

  const allQuery = useMemo(() => {
    const filters = Object.keys(query).reduce(
      (acc, key) => ({
        ...acc,
        ...(query[key]
          ? Array.isArray(query[key])
            ? query[key].length > 0
              ? { [key]: { $in: query[key] } }
              : {}
            : { [key]: query[key] }
          : {}),
      }),
      {}
    );

    return {
      ...filters,
      ...initialQuery,
    };
  }, [initialQuery, query]);

  return [allQuery, query, duration, setQuery, setDuration];
}

export function useReportController(url, duration, dateField = "date") {
  const [records, send, error, isLoading] = useResourcesByQuery(url, false);

  const apply = useCallback(
    (q = {}) => {
      send("SET_QUERY", {
        query: {
          ...q,
          ...(dateField
            ? {
                [dateField]: {
                  ...(duration.start ? { $gte: duration.start } : {}),
                  ...(duration.end ? { $lte: duration.end } : {}),
                },
              }
            : {}),
        },
      });
    },
    [send, dateField, duration.start, duration.end]
  );
  return [records, error, isLoading, apply];
}

export function ReportViewer({
  title,
  filters = [],
  columns = [],
  totals = [],
  records,
  groupsTitles = [],
  error,
  isLoading,
  hasChart = false,
  toolbarExtras = null,
  filterByDate,
  duration,
  setDuration,
  query,
  setQuery,
  onApply,
  hideIndex,
  inlineFiltersOptions = {},
  showGridBorders = false,
  hideDateOnPrint = false,
  children,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();
  const { t } = useTranslate();

  return (
    <PrintTemplate active={active} onPrintCompleted={onPrintCompleted}>
      <PageCard>
        <LoadingIndicator show={isLoading} />
        <ErrorAlert error={error} />
        <Stack>
          <ReportsToolbar
            title={title}
            filters={filters}
            onFiltersChange={() => setShowFilters(!showFilters)}
            columns={columns}
            records={records}
            onPrintRequest={onPrintRequest}
            hasChart={hasChart}
            onShowChartClick={() => setShowChart(!showChart)}
            toolbarExtras={toolbarExtras}
            // onSaveCurrentQuery={onSaveCurrentQuery}
          />
          {filterByDate && (
            <div className={hideDateOnPrint ? "no-print" : ""}>
              <DurationFilter duration={duration} onChange={setDuration} />
            </div>
          )}
          {showFilters && (
            <CardSection>
              <Grid container spacing={2}>
                {filters.map(
                  ({
                    name,
                    key,
                    options,
                    extras = {},
                    filters = {},
                    multiple = true,
                    type = "filter",
                    Component,
                  }) => {
                    if (type === "filter") {
                      return (
                        <FilterField
                          key={name}
                          grid={4}
                          source={name}
                          filters={filters}
                          name={name}
                          label={t(name)}
                          value={query[key] || []}
                          onChange={(val) =>
                            setQuery((prev) => ({ ...prev, [key]: val }))
                          }
                          {...extras}
                        />
                      );
                    }
                    if (type === "switch") {
                      return (
                        <FormSwitch
                          key={name}
                          label={t(name)}
                          value={query[key]}
                          onChange={(val) =>
                            setQuery((prev) => ({ ...prev, [key]: val }))
                          }
                          {...extras}
                        />
                      );
                    }
                    if (type === "select") {
                      return (
                        <FormSelectField
                          multiple={multiple}
                          options={options}
                          key={name}
                          grid={4}
                          label={t(name)}
                          value={query[key]}
                          onChange={(val) =>
                            setQuery((prev) => ({ ...prev, [key]: val }))
                          }
                          {...extras}
                        />
                      );
                    }
                    if (type === "custom") {
                      return (
                        <Grid item xs sm={4} key={name}>
                          <Component
                            onChange={(val) => {
                              // console.log(val);
                              setQuery((prev) => ({ ...prev, ...val }));
                            }}
                          />
                        </Grid>
                      );
                    }
                    return (
                      <FormTextField
                        key={name}
                        grid={4}
                        label={t(name)}
                        value={query[key]}
                        onChange={(val) =>
                          setQuery((prev) => ({ ...prev, [key]: val }))
                        }
                        {...extras}
                      />
                    );
                  }
                )}
              </Grid>
            </CardSection>
          )}
          {children}
          {showChart && <ReportChart data={records} columns={columns} />}
          {records.length > 0 && Array.isArray(records[0]) ? (
            records.map((group, i) => {
              return (
                <ReportTable
                  title={groupsTitles[i]}
                  key={i}
                  data={group}
                  columns={columns}
                  totals={totals}
                  hideIndex={hideIndex}
                  filters={inlineFiltersOptions}
                  showGridBorders={showGridBorders}
                />
              );
            })
          ) : (
            <ReportTable
              data={records}
              columns={columns}
              totals={totals}
              hideIndex={hideIndex}
              filters={inlineFiltersOptions}
            />
          )}
        </Stack>
        <div style={{ margin: "3rem" }}></div>
        <Portal>
          <Box position="fixed" bottom={24} right={24}>
            <Zoom in style={{ transitionDelay: "250ms" }}>
              <Fab color="primary" onClick={(e) => onApply()}>
                <Check />
              </Fab>
            </Zoom>
          </Box>
        </Portal>
      </PageCard>
    </PrintTemplate>
  );
}

export default function ReportManager({
  url,
  title,
  filters = [],
  columns = [],
  totals = [],
  recordsFunc = (data) => data,
  titlesFunc = () => [],
  hasChart = false,
  hideIndex = false,
  filterByDate = false,
  dateField = "date",
  initialQuery = {},
  inlineFilters = [],
  children,
  toolbarExtras = null,
  autoStart = false,
  showGridBorders = false,
  hideDateOnPrint = false,
}) {
  const [first, setFirst] = useState(autoStart ? 1 : 0);
  const [serverQuery, query, duration, setQuery, setDuration] =
    useReportQuery(initialQuery);
  const [records, error, isLoading, apply] = useReportController(
    url,
    duration,
    filterByDate ? dateField : null
  );

  useEffect(() => {
    if (first > 0) {
      apply(serverQuery);
      setFirst(first - 1);
    }
  }, [first, apply, serverQuery]);

  const onApply = () => {
    apply(serverQuery);
  };

  const modifiedRecords = recordsFunc(records);
  const groupsTitles = titlesFunc(modifiedRecords);

  const inlineFiltersOptions = useMemo(() => {
    return columns.reduce((acc, curr) => {
      if (!inlineFilters.includes(curr.name)) return acc;
      if (curr.type === "multi") {
        return {
          ...acc,
          [curr.name]: uniq(
            modifiedRecords
              .flatMap(prop(curr.name))
              .filter((val) => val && val !== "")
          ),
        };
      }
      return {
        ...acc,
        [curr.name]: uniq(
          modifiedRecords
            .map(prop(curr.name))
            .filter((val) => val && val !== "")
        ),
      };
    }, {});
  }, [modifiedRecords, inlineFilters, columns]);

  return (
    <ReportViewer
      title={title}
      filters={filters}
      columns={columns}
      totals={totals}
      records={modifiedRecords}
      groupsTitles={groupsTitles}
      error={error}
      isLoading={isLoading}
      inlineFiltersOptions={inlineFiltersOptions}
      duration={duration}
      query={query}
      setQuery={setQuery}
      setDuration={setDuration}
      toolbarExtras={toolbarExtras}
      hideIndex={hideIndex}
      hasChart={hasChart}
      onApply={onApply}
      filterByDate={filterByDate}
      showGridBorders={showGridBorders}
      hideDateOnPrint={hideDateOnPrint}
    >
      {children}
    </ReportViewer>
  );
}

export function ReportsToolbar({
  title,
  filters = [],
  onFiltersChange,
  columns,
  records,
  onPrintRequest,
  toolbarExtras,
  // hasChart,
  // onShowChartClick,
  // onSaveCurrentQuery,
}) {
  const { t } = useTranslate();

  return (
    <Toolbar disableGutters className="no-print">
      <Typography style={{ flex: "1 1 100%" }} variant="h4">
        {t(title)}
      </Typography>
      {toolbarExtras}
      {filters.length > 0 && (
        <Tooltip title={t("filters")}>
          <IconButton onClick={onFiltersChange}>
            <FilterList />
          </IconButton>
        </Tooltip>
      )}
      {/* {hasChart && (
        <IconButton onClick={onShowChartClick}>
          <PieChart />
        </IconButton>
      )} */}
      {/* <IconButton onClick={onSaveCurrentQuery}>
        <Bookmark />
      </IconButton> */}
      <Tooltip title={t("list")}>
        <IconButton component={RouterLink} to="/s/reports">
          <ViewList />
        </IconButton>
      </Tooltip>
      <PrintButton
        filterFunc={(item) => item.reports === true}
        onPrintRequest={onPrintRequest}
      />
      <DashboardShortcutPin title={title} />
      <ExcelExporterButton
        headers={columns.map(prop("name"))}
        data={records}
        filename={`${t(title)}-${t("report")}`}
      />
    </Toolbar>
  );
}

export function DurationFilter({ duration, onChange, initialType = "DAY" }) {
  const { t } = useTranslate();
  const { start, end } = duration;
  const [type, setType] = useState(initialType);

  const setDayDuration = () => {
    onChange({
      start: dayjs().startOf("DAY"),
      end: dayjs().endOf("DAY"),
    });
  };

  const setWeekDuration = () => {
    onChange({
      start: dayjs().startOf("WEEK").subtract(1, "day"),
      end: dayjs().endOf("WEEK").subtract(1, "day"),
    });
  };

  const setMonthDuration = () => {
    onChange({
      start: dayjs().startOf("MONTH"),
      end: dayjs().endOf("MONTH"),
    });
  };

  const setYearDuration = () => {
    onChange({
      start: dayjs().startOf("YEAR"),
      end: dayjs().endOf("YEAR"),
    });
  };

  const voidHandler = () => {};
  const withoutHandler = () => {
    onChange({});
  };

  const types = [
    { title: "WITHOUT", handler: withoutHandler },
    { title: "DAY", handler: setDayDuration },
    { title: "WEEK", handler: setWeekDuration },
    { title: "MONTH", handler: setMonthDuration },
    { title: "YEAR", handler: setYearDuration },
    { title: "CUSTOM", handler: voidHandler },
  ];

  const getDurationText = () => {
    switch (type) {
      case "DAY":
        return dayjs(start).format("YYYY-MM-DD");
      case "WEEK":
        return `${dayjs(start).format("YYYY-MM-DD")} : ${dayjs(end).format(
          "YYYY-MM-DD"
        )}`;
      case "MONTH":
        return dayjs(start).format("YYYY-MM");
      case "YEAR":
        return dayjs(start).format("YYYY");
      case "CUSTOM":
        return `${dayjs(start).format("YYYY-MM-DD HH:mm:ss")} : ${dayjs(
          end
        ).format("YYYY-MM-DD HH:mm:ss")}`;
      case "WITHOUT":
        return "";
      default:
        return "-";
    }
  };

  const nextDuration = () => {
    if (type === "MONTH") {
      onChange({
        start: dayjs(start).add(1, "MONTH").startOf("MONTH"),
        end: dayjs(start).add(1, "MONTH").endOf("MONTH"),
      });
    } else {
      onChange({
        start: dayjs(start).add(1, type),
        end: dayjs(end).add(1, type),
      });
    }
  };

  const previousDuration = () => {
    if (type === "MONTH") {
      onChange({
        start: dayjs(start).subtract(1, "MONTH").startOf("MONTH"),
        end: dayjs(start).subtract(1, "MONTH").endOf("MONTH"),
      });
    } else {
      onChange({
        start: dayjs(start).subtract(1, type),
        end: dayjs(end).subtract(1, type),
      });
    }
  };

  return (
    <CardSection>
      <Toolbar disableGutters>
        <ButtonGroup>
          {types.map((item) => (
            <Button
              key={item.title}
              color="primary"
              variant={type === item.title ? "contained" : "outlined"}
              onClick={(_) => {
                item.handler();
                setType(item.title);
              }}
            >
              {t(item.title)}
            </Button>
          ))}
        </ButtonGroup>
        {!["WITHOUT", "CUSTOM"].includes(type) && (
          <>
            <p style={{ flex: "1", textAlign: "center" }}>
              <strong>{getDurationText()}</strong>
            </p>
            <div className="arrows">
              <IconButton onClick={nextDuration}>
                <ArrowRight />
              </IconButton>
              <IconButton onClick={previousDuration}>
                <ArrowLeft />
              </IconButton>
            </div>
          </>
        )}
        {type === "CUSTOM" && (
          <Box px={2}>
            <Grid container spacing={2}>
              <FormDateTimeField
                grid={6}
                name="start"
                label={t("start")}
                value={start}
                onChange={(val) => onChange({ end, start: val })}
                onClear={() => onChange({ end })}
              />
              <FormDateTimeField
                grid={6}
                name="end"
                label={t("end")}
                value={end}
                onChange={(val) => onChange({ start, end: val })}
                onClear={() => onChange({ start })}
              />
            </Grid>
          </Box>
        )}
      </Toolbar>
    </CardSection>
  );
}

export function FilterField({ source, filters = {}, ...props }) {
  const [items] = useResourcesByQuery(source, true, filters);

  return <FormSelectField multiple options={items} {...props} />;
}

// TODO
// - MAKE width change with the width of the containers
// - how to filter the columns that works with the x and y axis
// - how to scale the x axis that is not linear to not linear
// - show the bar and pie charts
// -- DONE
// - how to make Y prices move the outside the axis

const noChartCols = ["summary", "notes"];

function ReportChart({ data = [], columns = [] }) {
  const { t } = useTranslate();
  const [chartType, setChartType] = useState("line");
  const [xAxis, setXAxis] = useState("reference");
  const [yAxis, setYAxis] = useState("total");

  const chartData = React.useMemo(
    () =>
      data.map((record) => ({
        x: record[xAxis],
        y: record[yAxis] / 100,
      })),
    [data, xAxis, yAxis]
  );

  const axes = React.useMemo(
    () =>
      columns
        .filter((col) => !noChartCols.includes(col.name))
        .map((col) => ({
          id: col.name,
          name: t(col.name),
        })),
    [t, columns]
  );
  return (
    <div>
      <XYPlot width={1200} height={450}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis tickPadding={30} />
        <LineSeries data={chartData} />
      </XYPlot>
      <Grid container spacing={2}>
        <FormSelectField
          grid={2}
          label="type"
          options={[
            { id: "line", name: t("line") },
            { id: "bar", name: t("bar") },
            { id: "pie", name: t("pie") },
          ]}
          value={chartType}
          onChange={setChartType}
        />
        <FormSelectField
          grid={2}
          label="xAxis"
          options={axes}
          value={xAxis}
          onChange={setXAxis}
        />
        <FormSelectField
          grid={2}
          label="yAxis"
          options={axes}
          value={yAxis}
          onChange={setYAxis}
        />
      </Grid>
    </div>
  );
}

export function ReportTable({
  title,
  data = [],
  columns = [],
  totals = [],
  hideIndex = false,
  filters = {},
  showGridBorders = false,
}) {
  const classes = useStyles();
  const { t } = useTranslate();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // console.log(data);

  const viewCol = (row, col) => {
    const { name, type } = col;
    const value = row[name];
    switch (type) {
      case "date":
        return !!value ? dayjs(value).format("YYYY-MM-DD") : "";
      case "datetime":
        return !!value ? dayjs(value).format("YYYY-MM-DD HH:mm") : "";
      case "money":
      case "balance":
        return !!value ? (Math.floor(value) / 100).toLocaleString() : 0;
      // return !!value ? Math.floor(value) / 100 : 0;
      case "percentage":
        return !!value
          ? (Math.round(value * 10000) / 100).toLocaleString() + "%"
          : 0;
      case "percentage_number":
        return !!value ? Math.round(value * 100).toLocaleString() + "%" : 0;
      case "translate":
        return !!value ? t(value) : "";
      case "boolean":
        return !!value ? <CheckCircleOutline /> : <CancelOutlined />;
      case "multi":
        return Array.isArray(value) ? value.join(" - ") : "";
      case "hours":
        return !!value ? Math.floor(value / 60) : 0;
      case "minutes":
        return !!value ? value % 60 : 0;
      case "account_balance":
        return !!value
          ? Math.floor(value) / 100 < 0
            ? `(${Math.abs(Math.floor(value / 100)).toLocaleString()})`
            : Math.floor(value / 100).toLocaleString()
          : 0;
      case "completebutton":
        return (
          <CompleteButton
            id={row.id}
            field={col.field}
            base={col.base}
            extras={{ [col.key]: row[col.key] }}
          />
        );
      case "link":
        return <Link to={`/s/${col.base}/${row[col.field]}`}>{value}</Link>;
      // case "html":
      //   return <span>{value}</span>;
      default:
        return value;
    }
  };

  const getColTotal = (col) => {
    const total = sumField(col.name)(filteredData);
    if (["balance", "money"].includes(col.type)) {
      // return Math.abs(total / 100).toLocaleString();
      return (total / 100).toLocaleString();
    }
    if (col.type === "hours") {
      return Math.floor(total / 60);
    }
    if (col.type === "minutes") {
      return total % 60;
    }
    if (col.type === "account_balance") {
      return !!total
        ? total < 0
          ? `(${Math.abs(Math.floor(total / 100)).toLocaleString()})`
          : Math.floor(total / 100).toLocaleString()
        : 0;
    }
    if (col.type === "percentage") {
      const nominator = sumField(col.nominator)(filteredData);
      const denominator = sumField(col.denominator)(filteredData);
      const value = nominator / denominator;
      return !!value
        ? (Math.round(value * 10000) / 100).toLocaleString() + "%"
        : 0;
    }
    return Math.abs(total);
  };

  const onFilterSelect = (field, value) => {
    if (selectedFilters[field]) {
      setSelectedFilters((old) => ({
        ...old,
        [field]: value,
      }));
    } else {
      setSelectedFilters((old) => ({ ...old, [field]: value }));
    }
  };

  const filteredData = useMemo(() => {
    return data.filter((record) => {
      return Object.keys(selectedFilters).every((filter) => {
        return (
          selectedFilters[filter].length === 0 ||
          (Array.isArray(record[filter])
            ? selectedFilters[filter].every((r) => record[filter].includes(r))
            : selectedFilters[filter].includes(record[filter]))
        );
      });
    });
  }, [data, selectedFilters]);

  return (
    <div className={showGridBorders ? "report-grid-borders" : ""}>
      {title && (
        <Typography style={{ flex: "1 1 100%" }} variant="h6">
          {title}
        </Typography>
      )}
      <TableContainer>
        <Table>
          <TableHead className={classes.highlight}>
            <TableRow>
              {!hideIndex && <TableCell>{t("table_index")}</TableCell>}
              {columns.map((col, index) => (
                <TableCell key={index}>
                  {col.title || t(col.label || col.name)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {filteredData.length > 0 && totals.length > 0 && (
            <tfoot className={classes.highlight}>
              <tr>
                {!hideIndex && <td></td>}
                {columns.map((col) => (
                  <td
                    key={col.name}
                    style={{
                      // textAlign: "center",
                      padding: "1em",
                      fontSize: "1rem",
                    }}
                  >
                    {totals.includes(col.name) && (
                      <strong>{getColTotal(col)}</strong>
                    )}
                  </td>
                ))}
              </tr>
            </tfoot>
          )}
          <TableBody>
            {Object.keys(filters).length > 0 && (
              <TableRow className="no-print">
                {!hideIndex && <TableCell></TableCell>}
                {columns.map((col) => (
                  <TableCell key={col.name}>
                    {Object.keys(filters).includes(col.name) &&
                      filters[col.name].length > 0 && (
                        <Autocomplete
                          renderTags={(value) =>
                            value.length > 1 ? value.length : value[0]
                          }
                          disableListWrap
                          fullWidth
                          limitTags={1}
                          multiple
                          options={filters[col.name]}
                          getOptionLabel={(option) => `${option}`}
                          value={selectedFilters[col.name] || []}
                          onChange={(e, newVal) => {
                            onFilterSelect(col.name, newVal);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        ></Autocomplete>
                      )}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {filteredData.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  {t("noResources")}
                </TableCell>
              </TableRow>
            )}
            {filteredData.length > 0 &&
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    className={index % 2 ? classes.strip : ""}
                    hover
                    key={index}
                  >
                    {!hideIndex && <TableCell>{index + 1}</TableCell>}
                    {columns.map((col) => (
                      <TableCell key={col.name}>
                        <Box display="block" maxWidth={200}>
                          <Typography
                            variant="body1"
                            noWrap={col.noWrap || false}
                          >
                            {viewCol(row, col)}
                          </Typography>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {filteredData.length > rowsPerPage && (
        <Box
          display="flex"
          justifyContent="center"
          className={classes.highlight}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </div>
  );
}

function completeResource({ id, base, field, extras = {} }) {
  return api.service(base).patch(id, {
    [field]: dayjs(),
    ...extras,
  });
}

function CompleteButton({ id, field, base, extras }) {
  const { mutate, isLoading, error } = useMutation(completeResource, {
    throwOnError: true,
  });

  const handleResourceCompleted = async () => {
    try {
      await mutate({ id, field, base, extras });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <LoadingIndicator show={isLoading} />
      <ErrorAlert error={error} />
      <IconButton
        variant="contained"
        color="primary"
        onClick={handleResourceCompleted}
      >
        <DoneAll />
      </IconButton>
    </>
  );
}

export const totalsColumns = [
  {
    name: "number_of_operations",
    type: "number",
  },
  {
    name: "minimum_ops",
    type: "money",
  },
  {
    name: "avg_ops",
    type: "money",
  },
  {
    name: "maximum_ops",
    type: "money",
  },
  {
    name: "subtotal",
    type: "money",
  },
  {
    name: "discount",
    type: "money",
  },
  {
    name: "tax",
    type: "money",
  },
  {
    name: "total",
    type: "money",
  },
  {
    name: "commission",
    type: "money",
  },
  {
    name: "cost",
    type: "money",
  },
];

export const linesColumns = [
  {
    name: "min_price",
    type: "money",
  },
  {
    name: "avg_price",
    type: "money",
  },
  {
    name: "max_price",
    type: "money",
  },
  {
    name: "avg_cost_price",
    type: "money",
  },
  {
    name: "quantity",
    type: "number",
  },
  {
    name: "returned",
    type: "number",
  },
  {
    name: "actual",
    type: "number",
  },
  {
    name: "subtotal",
    type: "money",
  },
  {
    name: "discount",
    type: "money",
  },
  {
    name: "total",
    type: "money",
  },
  {
    name: "cost",
    type: "money",
  },
  {
    name: "commission",
    type: "money",
  },
];

export const groupByCurrency = (records) =>
  Object.values(groupBy(prop("currency_id"), records));

export const extractCurrencyName = (records) =>
  records.flatMap((c) => {
    return Array.isArray(c) && c.length > 0 ? c[0].currency : undefined;
  });

export const totalsTransformer = (groupFn, key) => (records) =>
  Object.values(groupBy(groupFn, records)).map((group) => {
    const totals = group.reduce(
      (sum, record, index) => ({
        id: record[key],
        ...sum,
        ...record,
        minimum_ops:
          sum.minimum_ops < Number(record.total) && sum.minimum_ops > 0
            ? sum.minimum_ops
            : Number(record.total),
        maximum_ops:
          sum.maximum_ops > Number(record.total)
            ? sum.maximum_ops
            : Number(record.total),
        subtotal: sum.subtotal + Number(record.subtotal),
        total: sum.total + Number(record.total),
        discount: sum.discount + Number(record.discount),
        tax: sum.tax + Number(record.tax),
        commission: sum.commission + Number(record.commission),
        cost: sum.cost + Number(record.cost),
        profit: sum.profit + Number(record.profit),
      }),
      {
        minimum_ops: 0,
        maximum_ops: 0,
        subtotal: 0,
        total: 0,
        discount: 0,
        tax: 0,
        commission: 0,
        cost: 0,
        profit: 0,
      }
    );

    return {
      ...totals,
      avg_ops: Math.round(totals.total / group.length),
      number_of_operations: group.length,
    };
  });

export const linesTransformer = (groupFn, key) => (records) =>
  Object.values(groupBy(groupFn, records)).map((group) => {
    const totals = group.reduce(
      (sum, record, index) => ({
        id: record[key],
        ...sum,
        ...record,
        min_price:
          sum.min_price < Number(record.price) && sum.min_price > 0
            ? sum.min_price
            : Number(record.price),
        max_price:
          sum.max_price > Number(record.price)
            ? sum.max_price
            : Number(record.price),
        quantity: sum.quantity + Number(record.quantity),
        returned: sum.returned + Number(record.returned),
        actual: sum.actual + Number(record.actual),
        subtotal: sum.subtotal + Number(record.subtotal),
        discount: sum.discount + Number(record.discount),
        total: sum.total + Number(record.total),
        cost: sum.cost + Number(record.cost),
        commission: sum.commission + Number(record.commission),
        profit: sum.profit + Number(record.profit),
      }),
      {
        min_price: 0,
        avg_price: 0,
        max_price: 0,
        avg_cost_price: 0,
        quantity: 0,
        returned: 0,
        actual: 0,
        subtotal: 0,
        discount: 0,
        total: 0,
        cost: 0,
        commission: 0,
        profit: 0,
      }
    );
    return {
      ...totals,
      avg_price: Math.round(totals.total / totals.actual),
      avg_cost_price: Math.round(totals.cost / totals.actual),
    };
  });
