import React from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import FormTextField from "commons/components/FormTextField";
import ResourceRelations from "commons/components/ResourceRelations";
import { FormNumberField } from "commons/components/FormNumberField";
import { FormDateField } from "commons/components/FormDateField";
import dayjs from "dayjs";
import ResourceEditor from "commons/components/ResourceEditor";

export default function Editor({ send, current, updateModel, ...props }) {
  const { model, rules } = current.context;
  const [customers] = useResourcesByQuery("customers", true);
  const [employees] = useResourcesByQuery("employees", true);

  const onArrivalChange = (val) => {
    updateModel("arrival")(val);
    if (model.departure) {
      updateModel("nights")(dayjs(model.departure).diff(dayjs(val), "day"));
    }
  };

  const onDepratureChange = (val) => {
    updateModel("departure")(val);
    if (model.arrival) {
      updateModel("nights")(dayjs(val).diff(dayjs(model.arrival), "day"));
    }
  };

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormSelectField
        grid={4}
        options={customers}
        label="customer_id"
        value={model.customer_id}
        onChange={updateModel("customer_id")}
        error={rules.customer_id}
      />
      <FormSelectField
        grid={4}
        options={employees}
        label="operator_id"
        value={model.operator_id}
        onChange={updateModel("operator_id")}
        error={rules.operator_id}
      />
      <FormTextField
        grid={2}
        label="number"
        value={model.number}
        onChange={updateModel("number")}
        error={rules.number}
      />
      <FormSelectField
        grid={2}
        label="file_type"
        options={[
          { id: "normal", name: "normal" },
          { id: "optional", name: "optional" },
        ]}
        value={model.file_type}
        onChange={updateModel("file_type")}
        error={rules.file_type}
      />
      <FormDateField
        grid={4}
        label="arrival"
        value={model.arrival}
        onChange={onArrivalChange}
        error={rules.arrival}
      />
      <FormDateField
        grid={4}
        label="departure"
        value={model.departure}
        onChange={onDepratureChange}
        error={rules.departure}
      />
      <FormNumberField
        grid={4}
        label="nights"
        value={model.nights}
        onChange={updateModel("nights")}
        error={rules.nights}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
      />
      <GuestsManager value={model.guests} onChange={updateModel("guests")} />
    </ResourceEditor>
  );
}

function GuestsManager({ value, onChange }) {
  return (
    <ResourceRelations
      title="guests"
      value={value}
      onChange={onChange}
      model={{
        name: "",
        nationality: "",
        dob: null,
        notes: "",
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormTextField
            grid={3}
            label="name"
            value={record.name}
            onChange={onItemChange("name")}
          />
          <FormTextField
            grid={3}
            label="nationality"
            value={record.nationality}
            onChange={onItemChange("nationality")}
          />
          <FormDateField
            grid={3}
            label="dob"
            value={record.dob}
            onChange={onItemChange("dob")}
          />
          <FormTextField
            grid={3}
            label="notes"
            value={record.notes}
            onChange={onItemChange("notes")}
          />
        </>
      )}
    />
  );
}
