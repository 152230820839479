import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "products.name",
  },
  {
    name: "code",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "sale_price",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "purchase_price",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "cost_price",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "type",
    type: "translate",
    sortable: true,
    searchable: true,
    searchList: ["ONCE", "HOURLY", "DAILY", "MONTHLY", "YEARLY", "SESSIONS"],
  },
  {
    name: "threshold",
    type: "number",
    sortable: true,
    searchable: true,
  },
  {
    name: "saleable",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
  {
    name: "purchasable",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
  {
    name: "active",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
  {
    name: "category",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "categories.name",
  },
  {
    name: "image",
    type: "image",
    sortable: false,
    searchable: false,
  },
];

export default function List({ extras = [] }) {
  return (
    <ResourceListPage
      url="products"
      columns={columns}
      title="services"
      baseQuery={{
        type: {
          $in: [
            "ONCE",
            "HOURLY",
            "DAILY",
            "MONTHLY",
            "YEARLY",
            "SESSIONS",
            ...extras,
          ],
        },
      }}
    />
  );
}
