import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "customer",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "customers.name",
  },
  {
    name: "arrival",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "departure",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "number",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "operator",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "employees.name",
  },
  {
    name: "nights",
    type: "number",
    sortable: true,
    searchable: true,
  },
  // {
  //   name: "profit",
  //   type: "number",
  //   sortable: true,
  //   searchable: true,
  // },
  {
    name: "file_type",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "notes",
    type: "text",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="files" columns={columns} />;
}
