import React, { useMemo } from "react";
import ReportManager, {
  groupByCurrency,
} from "commons/components/ReportManager";
import useQueryAllResources from "commons/hooks/useQueryAllResources";
import useTranslate from "commons/hooks/useTranslate";
import { useHistory } from "react-router-dom";
import { IconButton, Tooltip } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";

const filters = [
  {
    type: "filter",
    name: "cruises",
    key: "cruise_id",
    extras: {
      optionLabel: "name",
    },
  },
];

const columns = [
  {
    name: "name",
    type: "text",
  },
  {
    name: "starts",
    type: "date",
  },
  {
    name: "ends",
    type: "date",
  },
  {
    name: "sales_count",
    type: "number",
  },
  {
    name: "guest_number",
    type: "number",
  },
  {
    name: "sales_avg",
    type: "money",
  },
  {
    name: "sales_total",
    type: "money",
    highlight: true,
  },
  {
    name: "purchases_count",
    type: "number",
  },
  {
    name: "purchases_avg",
    type: "money",
  },
  {
    name: "purchases_total",
    type: "money",
    highlight: true,
  },
  {
    name: "profit",
    type: "money",
    highlight: true,
  },
  {
    name: "profit_percentage",
    type: "percentage",
    nominator: "profit",
    denominator: "sales_total",
    highlight: true,
  },
];

export default function CruisesReport() {
  const { t } = useTranslate();
  const history = useHistory();
  const [currencies] = useQueryAllResources("currencies");

  const lookup = useMemo(
    () =>
      currencies.reduce((a, c) => {
        return {
          ...a,
          [c.id]: c.name,
        };
      }, {}),
    [currencies]
  );

  const extractCurrencyName = (records) => {
    return records.flatMap((c) => {
      return Array.isArray(c) && c.length > 0
        ? lookup[c[0].currency_id]
        : undefined;
    });
  };

  return (
    <ReportManager
      url="tourism-balance"
      title="cruisesReport"
      columns={columns}
      filters={filters}
      recordsFunc={(recs) => {
        return groupByCurrency(recs);
      }}
      titlesFunc={extractCurrencyName}
      totals={[
        "guest_number",
        "sales_count",
        "sales_total",
        "purchases_count",
        "purchases_total",
        "profit",
        "profit_percentage",
      ]}
      filterByDate
      initialQuery={{ type: "cruises" }}
      toolbarExtras={
        <Tooltip title={t("inactiveCruisesReport")}>
          <IconButton
            onClick={() => history.push(`/s/reports/inactive-cruises/`)}
          >
            <DeleteOutline />
          </IconButton>
        </Tooltip>
      }
    />
  );
}
