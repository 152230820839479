import React from "react";
import ReportManager from "commons/components/ReportManager";

const columns = [
  {
    name: "number",
    type: "link",
    field: "id",
    base: "files",
  },
  {
    name: "customer",
    type: "text",
  },
  {
    name: "arrival",
    type: "date",
  },
  {
    name: "departure",
    type: "date",
  },
];

export default function InactiveFilesReport() {
  return (
    <ReportManager
      url="inactive"
      title="inactiveFilesReport"
      columns={columns}
      initialQuery={{ type: "files" }}
      filterByDate
      // initialQuery={{ "sales.file_id": null, "purchases.file_id": null }}
    />
  );
}
