import React from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import { FormDateField } from "commons/components/FormDateField";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ProductConnector from "commons/components/ProductConnector";
import useTranslate from "commons/hooks/useTranslate";
import { Grid, Typography } from "@material-ui/core";
import FormSwitch from "commons/components/FormSwitch";
import {
  useBalance,
  TotalsGroup,
  TabbedSection,
  CommissionsBalance,
  PaymentsBalance,
} from "commons/components/Balance";
import Stack from "commons/components/Stack";
import ResourceListPage from "commons/components/ResourceListPage";
import CardSection from "commons/components/CardSection";

const base = "employees";

export default function Standalone({
  hideProductConnections = false,
  hideCommission = false,
}) {
  const { current, send, model, updateModel, id } =
    useControlledResourcePage(base);
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/employees/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "balance",
            path: `/s/employees/${id}/balance`,
            component: <Balance id={id} hideCommission={hideCommission} />,
          },
          ...(hideProductConnections
            ? []
            : [
                {
                  name: "products",
                  path: `/s/employees/${id}/products`,
                  component: <Products id={id} />,
                },
                {
                  name: "services",
                  path: `/s/employees/${id}/services`,
                  component: <Products services id={id} />,
                },
              ]),
        ]}
      />
    </PageCard>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;
  const [departments] = useResourcesByQuery("departments", true);
  const [grades] = useResourcesByQuery("grades", true);

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="address"
        value={model.address}
        onChange={updateModel("address")}
        error={rules.address}
      />
      <FormSelectField
        grid={3}
        label="department_id"
        options={departments}
        value={model.department_id}
        onChange={updateModel("department_id")}
        error={rules.department_id}
      />
      <FormSelectField
        grid={3}
        label="grade_id"
        options={grades}
        value={model.grade_id}
        onChange={updateModel("grade_id")}
        error={rules.grade_id}
      />
      <FormTextField
        grid={3}
        label="sale_commission"
        value={model.sale_commission}
        onChange={updateModel("sale_commission")}
        error={rules.sale_commission}
      />
      <FormTextField
        grid={3}
        label="primary_phone"
        value={model.primary_phone}
        onChange={updateModel("primary_phone")}
        error={rules.primary_phone}
      />
      <FormTextField
        grid={3}
        label="secondary_phone"
        value={model.secondary_phone}
        onChange={updateModel("secondary_phone")}
        error={rules.secondary_phone}
      />
      <FormTextField
        grid={3}
        label="email"
        value={model.email}
        onChange={updateModel("email")}
        error={rules.email}
      />
      <FormTextField
        grid={3}
        label="nid"
        value={model.nid}
        onChange={updateModel("nid")}
        error={rules.nid}
      />
      <FormDateField
        grid={3}
        label="dob"
        value={model.dob}
        onChange={updateModel("dob")}
        error={rules.dob}
      />
      <FormDateField
        grid={3}
        label="joined"
        value={model.joined}
        onChange={updateModel("joined")}
        error={rules.joined}
      />
      <FormDateField
        grid={3}
        label="terminated"
        value={model.terminated}
        onChange={updateModel("terminated")}
        error={rules.terminated}
      />
      <FormTextField
        multiline
        grid={6}
        label="qualifications"
        value={model.qualifications}
        onChange={updateModel("qualifications")}
        error={rules.qualifications}
      />
      <FormTextField
        multiline
        grid={6}
        label="custody"
        value={model.custody}
        onChange={updateModel("custody")}
        error={rules.custody}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceEditor>
  );
}

export function Products({
  id,
  relation = "employee-product",
  relationKey = "employee_id",
  ...props
}) {
  const { t } = useTranslate();
  return (
    <ProductConnector
      id={id}
      {...props}
      relation={relation}
      relationKey={relationKey}
      model={{
        employee_id: id,
        value: "0",
        permanent: false,
      }}
      header={
        <>
          <Grid item sm={9}>
            <Typography variant="subtitle1">{t("commission")}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="subtitle1">{t("permanent")}</Typography>
          </Grid>
        </>
      }
      rowRenderer={(record, onItemChange) => (
        <>
          <FormTextField
            grid={9}
            size="small"
            value={record.value}
            onChange={onItemChange("value")}
          />
          <FormSwitch
            grid={3}
            size="small"
            value={record.permanent}
            onChange={onItemChange("permanent")}
          />
        </>
      )}
    />
  );
}

const salariesColumns = [
  {
    name: "due_date",
    type: "datetime",
    sortable: true,
  },
  {
    name: "net_salary",
    type: "money",
    sortable: true,
  },
];

function Balance({ id, hideCommission = false }) {
  const { balance } = useBalance(id, "balance", "employees", [
    ...(hideCommission ? [] : ["salaries", "sales", "purchases"]),
    "employee-payments",
  ]);

  return (
    <Stack>
      <TotalsGroup
        labels={[
          ...(hideCommission ? [] : ["salaries", "sales", "purchases"]),
          "payments",
          "refunds",
          "balance",
        ]}
        values={balance}
      />
      <TabbedSection
        labels={[
          ...(hideCommission ? [] : ["salaries", "sales", "purchases"]),
          "payments",
          "refunds",
        ]}
        sections={[
          ...(hideCommission
            ? []
            : [
                <ResourceListPage
                  Wrapper={CardSection}
                  hideToolbar
                  url="salaries"
                  columns={salariesColumns}
                  baseQuery={{ employee_id: id }}
                  createButton={null}
                />,
                <CommissionsBalance
                  url="employee-sale"
                  filter={{ employee_id: id }}
                  rowClickBase="sales"
                  rowClickField="sale_id"
                />,
                <CommissionsBalance
                  url="employee-purchase"
                  filter={{ employee_id: id }}
                  rowClickBase="purchases"
                  rowClickField="purchase_id"
                />,
              ]),
          <PaymentsBalance
            id={id}
            url="employee-payments"
            field="employee_id"
          />,
          <PaymentsBalance
            id={id}
            url="employee-payments"
            field="employee_id"
            refund
          />,
        ]}
      />
    </Stack>
  );
}
