export default {
  developed_by: "تطوير",
  language: "اللغة",
  dashboard: "الرئيسية",
  settings: "الاعدادات",
  username: "اسم المستخدم",
  password: "كلمة السر",
  oldPassword: "كلمة السر الحالية",
  passwordConfirm: "تأكيد كلمة السر",
  password_confirmation: "تأكيد كلمة السر",
  changePassword: "تغيير كلمة السر",
  login: "تسجيل الدخول",
  logout: "الخروج",
  roles: "التصريحات",
  "app-settings": "اعدادات البرنامج",
  appSettings: "اعدادات البرنامج",
  hr: "الموارد البشرية",
  manage: "الادارة",
  finance: "الماليات",
  policies: "سياسات الاسعار",
  policy: "سياسة سعر",
  policy_id: "سياسة السعر",
  sale_policy_id: "سياسة سعر الايراد",
  purchase_policy_id: "سياسة سعر الشراء",
  "policies.name": "سياسة السعر",
  "customer-groups": "مجموعات العملاء",
  customer_groups: "مجموعات العملاء",
  "customer-group": "مجموعة عملاء",
  "customer_groups.name": "اسم مجموعة عملاء",
  "users.username": "اسم المستخدم",
  group_id: "مجموعة عملاء",
  suppliers: "الموردين",
  supplier: "مورد",
  supplier_id: "المورد",
  "supplier-payment": "دفع مورد",
  customers: "العملاء",
  customer: "عميل",
  accounts: "الحسابات",
  account: "حساب",
  transactions: "القيود",
  transaction: "القيد",
  departments: "الأقسام",
  department: "قسم",
  department_id: "قسم",
  "departments.name": "القسم",
  employees: "الموظفين",
  employee: "موظف",
  users: "المستخدمين",
  facilities: "المواقع",
  facility: "موقع",
  businessPlan: "خطة العمل",
  contractors: "المتعاقدون",
  contractor: "المتعاقد",
  "form-templates": "قوالب النماذج",
  "form-template": "قالب نموذج",
  "print-templates": "قوالب الطباعة",
  "print-template": "قالب طباعة",
  forms: "النماذج",
  form: "نموذج",
  attendances: "الحضور",
  attendance: "حضور",
  leaves: "الاجازات",
  evaluations: "التقييمات",
  evaluation: "تقييم",
  salaries: "الرواتب",
  salary: "راتب",
  salary_lines: "بنود الراتب",
  salary_line: "بند",
  tasks: "المهمات",
  task: "مهمة",
  shifts: "الورديات",
  shift: "وردية",
  shift_id: "الوردية",
  "shifts.name": "الوردية",
  schedules: "مواعيد العمل",
  schedule: "موعد عمل",
  "leave-types": "أنواع الاجازات",
  "leave-type": "نوع اجازة",
  leave_type: "نوع اجازة",
  leaveType: "نوع اجازات",
  leave_type_id: "نوع الاجازة",
  leave: "الاجازة",
  grades: "الدرجات",
  grade: "درجة",
  grade_id: "الدرجة",
  "grade-lines": "بنود الدرجة",
  "grades.name": "الدرجة",
  "vacation-days": "أيام الاجازات",
  vacationDay: "يوم اجازة",
  "vacation-day": "يوم اجازة",
  reports: "التقارير",
  actionsReport: "تقرير الاجراءات",
  "actions-report": "تقرير الاجراءات",
  overviewReport: "تقرير عام",
  "overview-report": "تقرير عام",
  salesReport: "تقرير الايرادات",
  purchasesReport: "تقرير المصروفات",
  formsReport: "تقرير النماذج",
  tasksReport: "تقرير المهمات",
  saleLine: "بند عملية ايراد",
  priceInline: "سعر سريع",
  chargeInline: "خصم سريع",
  id: "رقم التعريف",
  name: "الاسم",
  address: "العنوان",
  type: "النوع",
  phone: "الهاتف",
  primary_phone: "هاتف رئيسي",
  secondary_phone: "هاتف ثانوي",
  email: "البريد الالكتروني",
  noResources: "لا توجد عناصر مطابقة للطلب ",
  rowsPerPage: "عنصر في الصفحة",
  of: "من",
  print: "طباعة",
  advancedSearch: "بحث متقدم",
  field: "الحقل",
  fields: "الحقول",
  before: "قبل",
  after: "بعد",
  and: "و",
  or: "أو",
  line: "بند",
  contains: "يحتوي",
  exact: "مطابق",
  value: "القيمة",
  applied_value: "القيمة الفعلية",
  equals: "يساوي",
  less: "أصغر",
  greater: "أكبر",
  search: "بحث",
  cancel: "الغاء",
  andCondition: "بحث بجميع الشروط",
  export: "تصدير",
  columns: "الأعمدة",
  description: "الوصف",
  show: "عرض",
  add: "اضافة",
  edit: "تعديل",
  editor: "تحرير",
  remove: "حذف",
  user: "المستخدم",
  role: "التصريح",
  permissions: "البنود",
  filter: "تنقية",
  label: "الصفة",
  labels: "النصوص",
  selected: "محدد",
  new: "جديد",
  list: "القائمة",
  delete: "حذف",
  loading: "جاري التحميل",
  notes: "ملاحظات",
  previous: "السابق",
  next: "التالي",
  history: "الاجراءات",
  report: "تقرير",
  credit_limit: "حد الائتمان",
  dob: "تاريخ الميلاد",
  joined: "تاريخ الانضمام",
  terminated: "تاريخ الانهاء",
  qualifications: "المؤهلات",
  custody: "العهدة",
  nid: "الرقم القومي",
  avatar: "صورة",
  date: "التاريخ",
  reason: "السبب",
  categories: "تصنيفات",
  category: "تصنيف",
  "categories.name": "التصنيف",
  category_id: "التصنيف",
  products: "أصناف",
  "products.name": "صنف",
  sales: "الايرادات",
  SALES: "الايرادات",
  purchases: "المصروفات",
  PURCHASES: "المصروفات",
  purchase: "عملية شراء",
  storage: "تخزين",
  image: "صورة",
  saleable: "للايرادات",
  purchasable: "للمصروفات",
  stockable: "للتخزين",
  active: "نشط",
  code: "الكود",
  supplier_code: "كود المورد",
  storage_code: "كود المخزن",
  purchase_price: "سعر الشراء",
  cost_price: "سعر التكلفة",
  cost: "التكلفة",
  msrp: "سعر مقترح من المصنع",
  composite: "مركب",
  allow_fractions: "بالوزن",
  sale_price: "سعر البيع",
  storage_ratio: "معامل التخزين",
  units: "الأحجام",
  variants: "البدائل",
  components: "المكونات",
  product_ratio: "نسبة الصنف",
  service_ratio: "نسبة الخدمة",
  variant: "البديل",
  variant_ratio: "نسبة البديل",
  component: "المكون",
  component_ratio: "نسبة المكون",
  inventory: "ادارة المخزن",
  transfers: "ادارة المخزن",
  transfer: "حركة المخزن",
  reference: "رقم مستند",
  "sales.reference": "رقم مستند",
  deduct: "سحب",
  move: "نقل",
  assemble: "تجميع",
  disassemble: "تفكيك",
  from: "من",
  to: "الي",
  min_level: "الحد الأدني",
  max_level: "الحد الاقصي",
  max_allowed: "أقصي عدد",
  reorder_level: "حد الطلب",
  max_in_sale: "حد الايراد",
  current_qty: "الكمية الحالية",
  change_qty: "التغيير",
  after_qty: "الكمية بعد",
  notice: "ملحوظة",
  summary: "ملخص",
  serial: "رقم مسلسل",
  mfg_date: "تاريخ التصنيع",
  exp_date: "تاريخ الانتهاء",
  quantity: "الكمية",
  showChangesOnly: "عرض التغييرات",
  searchByCode: "بحث بالكود",
  searchByName: "بحث بالاسم",
  showHasQuantityOnly: "له رصيد",
  showHasNoQuantityOnly: "بدون رصيد",
  sale: "عملية ايراد",
  services: "الخدمات",
  service: "خدمة",
  reference_number: "رقم العملية",
  count: "العدد",
  subtotal: "المجموع",
  price: "السعر",
  discount_total: "الخصم",
  charge_total: "رسوم",
  total: "اجمالي",
  paid: "مدفوع",
  remaining: "متبقي",
  customer_balance: "رصيد العميل",
  balance: "الرصيد",
  BALANCE: "الرصيد",
  pay: "سداد",
  collect: "تحصيل",
  returns: "المرتجعات",
  returned_amount: "الباقي",
  firstBackupPath: "مسار النسخة الاحتياطية الأول",
  secondBackupPath: "مسار النسخة الاحتياطية الثاني",
  backupDuration: "مدة النسخة الاحتياطية",
  autoPrintOnPayment: "طباعة عند الدفع",
  autoStockChange: "تعديل تلقائي للمخزون",
  autoFulfilStocks: "تسليم تلقائي للمخزون",
  autoPrintOnStockChange: "طباعة تلقائي عند تعديل المخزون",
  debit: "مدين",
  credit: "دائن",
  organize: "تنظيم",
  contacts: "جهات الاتصال",
  contact: "جهة اتصال",
  messages: "الرسائل",
  attachments: "ملفات",
  attachment: "ملف",
  inbox: "صندوق الوارد",
  outbox: "صندوق الصادر",
  sender: "الراسل",
  recipient: "المرسل اليه",
  read: "تاريخ القراءة",
  path: "المسار",
  basic_salary: "الراتب الاساسي",
  base_salary: "الراتب الاساسي",
  sale_commission: "عمولة الايراد",
  commission: "عمولة",
  commission_value: "قيمة العمولة",
  allowed_late_minutes: "مدة التأخير المسموحة بالدقيقة",
  annual_allowed: "المسموح سنويأ",
  applied: "تطبق",
  min: "الحد الأدني",
  max: "الحد الأقصي",
  always: "دائما",
  extraHours: "ساعات عمل زيادة",
  extraShifts: "ورديات زيادة",
  "employees.name": "الموظف",
  employee_id: "الموظف",
  rate: "التقييم",
  startsWith: "يبدأ",
  starts: "تبدأ",
  started: "تبدأ",
  ends: "تنتهي",
  finished: "تنتهي",
  poor: "ضعيف",
  fair: "مقبول",
  good: "جيد",
  excellent: "ممتاز",
  due_amount: "المبلغ المستحق",
  payments: "المدفوعات",
  refunds: "مسترد",
  amount: "المبلغ",
  amount_in_currency: "المبلغ بالعملة",
  "form_templates.name": "قالب النموذج",
  form_templates: "قوالب النماذج",
  template: "قالب النموذج",
  form_template_id: "قالب النموذج",
  created: "انشاء",
  create: "انشاء",
  updated: "تعديل",
  update: "تعديل",
  assignee: "تكليف الي",
  assigned_to: "تكليف الي",
  body: "التفاصيل",
  created_by: "انشاء",
  assigner: "تكليف من",
  due: "الموعد",
  reminder: "تنبيه",
  completed: "منتهي",
  not_completed: "غير منتهي",
  duration_in_hours: "المدة بالساعة",
  active_from: "نشط من",
  active_to: "نشط الي",
  facility_id: "الموقع",
  enter: "دخول",
  exit: "خروج",
  hours: "عدد الساعات",
  hour: "ساعة",
  operations: "عمليات تجارية",
  catalog: "كتالوج",
  discounts: "التخفيضات",
  discount: "تخفيض",
  taxes: "رسوم",
  tax: "رسوم",
  installments: "الأقساط",
  installment: "قسط",
  print_name: "اسم للطباعة",
  conditions: "الشروط",
  min_price: "أقل سعر",
  max_price: "أعلي سعر",
  avg_price: "متوسط سعر",
  avg_cost_price: "متوسط سعر التكلفة",
  min_quantity: "أقل كمية",
  max_value: "أقصي قيمة للعملية",
  reward: "مكافأة",
  rewards: "مكافأت",
  penalties: "جزاءات",
  discount_value: "قيمة العرض",
  charge_value: "القيمة",
  absolute: "قيمة مطلقة",
  percentage: "نسبة مئوية",
  product: "صنف",
  minimum_charge: "الحد الأدنى",
  "asset-groups": "مجموعات الاصول",
  "asset-group": "مجموعة أصول",
  asset_group_id: "المجموعة",
  asset_group: "المجموعة",
  assets: "الأصول",
  asset: "أصول",
  acquired: "تاريخ الحصول عليه",
  disposed: "تاريخ الانهاء",
  customer_id: "العميل",
  "customers.name": "العميل",
  sale_id: "عملية الايراد",
  due_date: "تاريخ الاستحقاق",
  paid_on: "تاريخ التحصيل",
  fines: "غرامة",
  sessions: "جلسات",
  session: "جلسة",
  category_sorting: "ترتيب التصنيفات",
  difference: "الفارق",
  offerings: "منتجات",
  subtract: "خصم",
  charge_type: "النوع",
  value_type: "نوع القيمة",
  ONCE: "مرة واحدة",
  HOURLY: "بالساعة",
  DAY: "يوم",
  DAILY: "باليوم",
  MONTHLY: "بالشهر",
  MONTH: "شهر",
  YEARLY: "بالسنة",
  PIECE: "بالقطعة",
  WEIGHT: "بالوزن",
  AREA: "بالمساحة",
  VOLUME: "بالحجم",
  SESSIONS: "جلسات",
  threshold: "الحد",
  allowNegativeStocks: "السماح برصيد مخزن سلبي",
  utilizations: "الأنشطة اليومية",
  utilization: "الأنشطة اليومية",
  actions: "النشاط",
  action: "النشاط",
  comment: "تعليق",
  unit_value: "قيمة الوحدة",
  total_value: "قيمة كلية",
  table_index: "م",
  "stock-levels": "رصيد المخزن",
  shortages: "نواقص المخزون",
  movements: "حركة المخزن",
  expired: "مخزون منتهي الصلاحية",
  entities: "الأفراد",
  misc: "متنوع",
  start: "من",
  end: "إلي",
  WEEK: "أسبوع",
  YEAR: "سنة",
  CUSTOM: "مخصص",
  duration: "مدة",
  duration_start: "بداية المدة",
  duration_end: "نهاية المدة",
  salesReports: "تقارير الايرادات",
  purchasesReports: "تقارير المصروفات",
  salesByOperation: "الايرادات بالعملية",
  salesByProduct: "الايرادات بالمنتج",
  salesByCustomer: "الايرادات بالعميل",
  salesByCategory: "الايرادات بالتصنيف",
  salesByUser: "الايرادات بالمستخدم",
  purchasesByOperation: "المصروفات بالعملية",
  purchasesByProduct: "المصروفات بالمنتج",
  purchasesBySupplier: "المصروفات بالمورد",
  purchasesByCategory: "المصروفات بالتصنيف",
  purchasesByUser: "المصروفات بالمستخدم",
  profit_total: "الربح",
  collected: "تحصيل",
  tendered: "مدفوع",
  profit: "الربح",
  printReceipt: "طباعة ايصال",
  printStocks: "طباعة مخزون",
  current: "الحالي",
  number_of_operations: "عدد العمليات",
  CASH: "نقدي",
  WIRE_TRANSFER: "تحويل بنكي",
  CREDIT_CARD: "بطاقة ائتمان",
  CHEQUE: "شيك",
  payment_method: "طريقة الدفع",
  method: "الطريقة",
  minimum_ops: "أقل عملية",
  maximum_ops: "أعلي عملية",
  avg_ops: "متوسط العملية",
  accountsBalance: "أرصدة الحسابات",
  customersReport: "أرصدة العملاء",
  suppliersReport: "أرصدة الموردين",
  "accounts.code": "كود الحساب",
  "accounts.name": "اسم الحساب",
  "transactions.date": "التاريخ",
  "sale-line-price": " سعر بند ايراد",
  "purchase-line-price": "سعر بند شراء",
  "sale-line": "بند عملية ايراد",
  "purchase-line": "بند عملية شراء",
  "suppliers.name": "اسم المورد",
  operation_type: "نوع العملية",
  operation_id: "رقم التعريف",
  total_quantity: "الكمية",
  product_name: "اسم الصنف",
  product_code: "كود الصنف",
  size: "الحجم",
  fontSize: "حجم الخط",
  permanent: "دائم",
  penalty: "جزاء",
  commissions: "عمولات",
  operations_count: "عدد العمليات",
  operations_avg: "متوسط العمليات",
  operations_min: "أدني العمليات",
  operations_max: "أقصي العمليات",
  operations_total: "اجمالي العمليات",
  payments_count: "عدد الدفع",
  payments_avg: "متوسط الدفع",
  payments_min: "أدني الدفع",
  payments_max: "أقصي الدفع",
  positive_payments: "دفع له",
  negative_payments: "دفع عليه",
  payments_total: "فارق الدفع",
  add_by_shift: "اضافة بالوردية",
  add_single: "اضافة فردي",
  add_by_import: "استيراد",
  period: "المدة",
  period_start_date: "بداية المدة",
  period_end_date: "نهاية المدة",
  net_salary: "صافي الراتب",
  "sales.date": "تاريخ",
  entries: "بنود",
  on_subtotal: "علي المجموع",
  addHeading: "اضافة عنوان",
  addTextField: "اضافة حقل نص",
  addDateField: "اضافة حقل تاريخ",
  addSingleChoose: "اضافة اختيار فردي",
  addMultiChoose: "اضافة اختيار متعدد",
  addTextarea: "اضافة نص كبير",
  addTable: "اضافة جدول",
  addSelect: "اضافة حقل اختيار",
  addUpload: "اضافة رفع ملف",
  rows: "الصفوف",
  showIndex: "عرض مسلسل",
  defaultValue: "قيمة افتراضية",
  rowSpan: "أمتداد صفوف",
  colSpan: "امتداد أعمدة",
  addRow: "أضافة صف",
  addColumn: "أضافة عمود",
  disabled: "منع التعديل",
  highlighted: "تظليل",
  steps: "خطوات",
  elapsed_duration: "المدة المنقضية",
  minute: "دقيقة",
  notification: "تنبيه",
  notifications: "تنبيهات",
  projects: "المشروعات",
  project: "مشروع",
  logo: "الشعار",
  company_name: "اسم الشركة",
  theme: "الألوان",
  brand: "العلامة التجارية",
  theme_main_color: "اللون الرئيسي",
  theme_secondary_color: "اللون الثانوي",
  theme_background_color: "لون الخلفية",
  theme_surface_color: "لون الاقسام",
  save: "حفظ",
  length: "طول",
  width: "عرض",
  height: "ارتفاع",
  currency: "عملة",
  debit_in_currency: "مدين (عملة)",
  credit_in_currency: "دائن (عملة)",
  creator: "المستخدم",
  approver: "تأكيد",
  approve: "تأكيد",
  "service-locations": "أماكن الخدمات",
  service_locations: "أماكن الخدمات",
  "service-location": "مكان خدمات",
  service_location: "مكان خدمة",
  fulfilled: "تاريخ التنفيذ",
  "audit-logs": "سجل الأحداث",
  auditable_type: "العنصر",
  auditable_id: "رقم التعريف",
  event: "الحدث",
  currencies: "العملات",
  currency_rate: "معدل",
  currenciesBalance: "أرصدة العملات",
  mustHaveLines: "لا توجد بنود في العملية",
  "my-tasks": "مهماتي",
  "customer-payment": "دفع عميل",
  "employee-payment": "دفع موظف",
  "contractor-payment": "دفع متعاقد",
  "access-groups": "مجموعات الوصول",
  access_groups: "مجموعات الوصول",
  "access-group": "مجموعة الوصول",
  "facilities.name": "الموقع",
  header: "الترويسة",
  footer: "التذييل",
  addText: "اضافة نص",
  addImage: "اضافة صورة",
  addLine: "اضافة فاصل",
  content: "النص",
  PRODUCT_NOT_FOUND: "غير موجود",
  MAX_IN_SALE: "أقصي كمية للعملية",
  NO_STOCKS: "لا يوجد مخزون كافي",
  RETURN_QUANTITY: "يجب اعادة الكمية",
  CANNOT_RETURN: "لا يمكن اعادة الكمية",
  returned: "مرتجع",
  actual: "فعلي",
  complete: "انهاء",
  removeRow: "حذف صف",
  removeColumn: "حذف عمود",
  closed: "منتهي",
  connect: "ربط",
  contractorsReport: "تقرير المتعاقدون",
  employeesReport: "تقرير الموظفين",
  sales_total: "الايرادات",
  purchases_total: "المصروفات",
  salaries_total: "الرواتب",
  commissions_total: "العمولات",
  close: "اغلاق",
  open: "فتح",
  fastAdd: "انشاء سريع",
  return: "مرتجع",
  operation: "عملية",
  accountingReportsTemplates: "نماذج التقارير",
  accountingReports: "تقارير الحسابات",
  ACCOUNT: "حساب",
  LABEL: "عنوان",
  SUM_SECTION: "مجموع القسم",
  SUM_PREVIOUS: "مجموع السابق",
  DIFFERENCE: "الفارق",
  xAxis: "المحور الأفقي",
  yAxis: "المحور الرأسي",
  NOT_AUTHORIZED: "لا تمتلك التصريح",
  top: "أعلي",
  bottom: "أسفل",
  left: "يسار",
  right: "يمين",
  storage_tags: "علامات التخزين",
  financeOverview: "الوضع العام",
  stocks_total: "قيمة المخزون",
  customer_payments: "مدفوعات العملاء",
  supplier_payments: "سداد الموردين",
  employee_payments: "رواتب وعمولات",
  payments_difference: "فارق المدفوعات",
  bookmark: "حفظ البحث",
  counter: "العداد",
  translations: "الترجمات",
  translation: "الترجمة",
  languages: "اللغات",
  direction: "الاتجاه",
  rtl: "من اليمين لليسار",
  ltr: "من اليسار لليمين",
  calculateSalary: "حساب الراتب",
  general: "عام",
  statement: "البيان",
  attendance_percentage: "نسبة الحضور",
  absence_percentage: "نسبة الغياب",
  uncounted_percentage: "غير مسجل",
  PRESENT: "حضور",
  ABSENT: "غياب",
  printNoStorage: "طباعة الأصناف غير المخزنة",
  printComponents: "طباعة المكونات",
  title: "عنوان",
  options: "الخيارات",
  purchase_value: "قيمة الشراء",
  sale_value: "قيمة البيع",
  last_price_profit: "ربح سعر شراء",
  stock_value_profit: "ربح قيمة فعلية",
  BALANCED: "بدون فارق",
  NEGATIVE_BALANCE: "رصيد سالب",
  POSITIVE_BALANCE: "رصيد موجب",
  content_font_size: "حجم الخط",
  content_line_height: "بين السطور",
  bgColor: "لون الخلفية",
  fontColor: "لون الخط",
  padding: "مسافة محيطة",
  apply_inventory: "تنفيذ المخزن",
  saleComponent: "تجميع للبيع",
  compositeIn: "اضافة صنف مجمع",
  compositeOut: "خصم صنف مجمع",
  componentOut: "خصم مكون",
  WITHOUT: "بدون",
  collapsed: "مجمع",
  serialByCustomer: "الأرقام المسلسلة",
  addToDashboard: "أضف للرئيسية",
  order: "الترتيب",
  compactView: "عرض مجمل",
  duplicate: "نسخة مكررة",
  filters: "تصفية",
  merge: "دمج",
  split: "فصل",
  enter_only: "دخول فقط",
  exit_only: "خروج فقط",
  import: "استيراد",
  data: "البيانات",
  back: "العودة",
  success: "نجاح",
  errors: "اخطاء",
  product_id: "رقم تعريف الصنف",
  parent_account_name: "تحت حساب ",
  parent_account_id: "تحت حساب ",
  stocksTotal: "مخزن",
  autoFulfilOnPrint: "تسليم عند طباعة المخزون",
  stocks_fulfilled: "تم التسليم",
  stocks_unfulfilled: "لم يتم التسليم",
  NOT_AUTHORIZED_TO_RETURN: "غير مصرح بالمرتجع",
  CANNOT_OVER_RETURN: "المرتجع تجاوز الكمية",
  CANNOT_REMOVE_LINE: "غير مصرح بحذف بند",
  NEGATIVE_STOCKS_NOT_ALLOWED: "غير مسموح  بمخزون سلبي",
  fulfill_all: "تسليم الكل",
  unfulfill: "الغاء تسليم",
  forceCustomerPhoneAddress: "هاتف وعنوان العميل اجباري",
  showAllFacilitiesProducts: "عرض منتجات كل المواقع",
  phoneSearch: "البحث بالهاتف",
  print_notes: "ملاحظات الطباعة",
  daily_counter: "عدد اليوم",
  print_daily_counter: "طباعة عدد اليوم",
  others: "آخرى",
  group_by_category: "تجميع بالتصنيف",
  discount_price: "بعد الخصم",
  discount_amount: "قيمة الخصم",
  "fast-discount": "خصم سريع",
  items_count: "عدد العناصر",
  page: "الصفحة",
  page_count: "عدد الصفحات",
  priceAfterDiscount: "سعر بعد الخصم",
  seperateLines: "منفصل",
  "stock-line-price": "سعر مخزون",
  "customer-payments": "مدفوعات العملاء",
  "supplier-payments": "سداد الموردين",
  "over return-line": "مرتجع فوق الكمية",
  "saved-line": "بند",
  "balance-statement": "كشف حساب",
  "stock-value": "قيمة المخزون",
  "session-attendance": "حضور الجلسات",
  "session-attendances": "حضور الجلسات",
  "barcode-printing": "طباعة الباركود",
  "operation-cost": "تكلفة العملية",
  "operation-profit": "ربح العملية",
  "reports/accounting-reports-templates": "نماذج التقارير",
  "two-columns": "عمودين",
  "three-columns": "3 أعمدة",
  "four-columns": "4 أعمدة",
  "line-price": "سعر بند",
  "over-credit": "تخطي حد الائتمان",
  "over-limit": "تخطى حد العملية",
  "under-cost": "تخطي التكلفة",
  "over-reorder-level": "تخطى حد الطلب",
  "stock-line": "بند مخزون",
  "tax-line": "بند ضريبة",
  "discount-line": "بند خصم",
  "commission-line": "بند عمولة",
  "sales-reports": "تقارير الايرادات",
  "purchases-reports": "تقارير المصروفات",
  "inventory-reports": "تقارير المخازن",
  "accounting-reports": "تقارير الحسابات",
  "entities-reports": "تقارير الأفراد",
  "misc-reports": "تقارير متنوع",
  "ongoing-services": "الخدمات الجارية",
  "ending-services": "الخدمات المنتهية",
  print_date: "طباعة التاريخ",
  print_customer: "طباعة العميل",
  "rules.required": "مطلوب",
  "rules.min": "لا يطابق الحد الأدني المطلوب",
  "rules.max": "لا يطابق الحد الاقصي المطلوب",
  "rules.confirmed": "التأكيد غير مطابق",
  "rules.integer": "مطلوب رقم صحيح",
  "rules.debit_credit_match": "مطلوب تساوي القيم",
  "rules.different": "مطلوب اختلاف القيمتين",
  "rules.regex": "غير صحيح",
};
