import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "ship",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "service_locations.name",
  },
  {
    name: "starts",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "ends",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "captain",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "guest_number",
    type: "number",
    sortable: true,
    searchable: true,
  },
  {
    name: "notes",
    type: "text",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="cruises" columns={columns} />;
}
